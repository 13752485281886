body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "UMedium";
  src: url(./assets/fonts/Ubuntu/Ubuntu-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "UMediumItalic";
  src: url(./assets/fonts/Ubuntu/Ubuntu-MediumItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: "UBold";
  src: url(./assets/fonts/Ubuntu/Ubuntu-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: "URegular";
  src: url(./assets/fonts/Ubuntu/Ubuntu-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Museo500";
  src: url(./assets/fonts/Museo/Museo500-Regular.otf);
  font-display: swap;
}
@font-face {
  font-family: "Museo700";
  src: url(./assets/fonts/Museo/Museo700-Regular.otf);
  font-display: swap;
}



#slideQueHacemos .swiper-pagination-bullet {
  height: 17px !important;
  width: 17px !important;
  background: #FFFFFF !important;
  border: 1px solid #707070;
  opacity: 0.5
}
#slideQueHacemos .swiper-pagination-bullet-active {
  height: 17px !important;
  width: 17px !important;
  background: #FFFFFF !important;
  border: 1px solid #707070;
  opacity: 1
}
/* #slideQueHacemos .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 50px !important
} */
@media (min-width: 960px) {
  #slideQueHacemos .swiper-container{
    min-height: 500px;
  }
}
@media (max-width: 960px) {
  #slideQueHacemos .swiper-container{
    height: 600px
  }
}

#slideQueHacemos .swiper-pagination {
  bottom: 20px !important;
}
.react-parallax-background-children{
  width: 100% !important
}

#slideFasesProd .swiper-pagination-bullet {
  height: 17px !important;
  width: 17px !important;
  margin: 0px 20px !important;
  background: #8FC375 !important;
  opacity: 0.5
}
#slideFasesProd .swiper-pagination-bullet-active {
  height: 17px !important;
  width: 17px !important;
  background: #8FC375 !important;
  opacity: 1
}
#slideClientes .swiper-pagination-bullet {
  height: 17px !important;
  width: 17px !important;

  background: #8FC375 !important;
  opacity: 0.5
}
#slideFasesProd .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0px 30px !important;
}
#slideClientes .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0px 20px 10px  !important;
}
#slideClientes .swiper-pagination-bullet-active {
  height: 17px !important;
  margin: 0px 20px !important;
  width: 17px !important;
  background: #8FC375 !important;
  opacity: 1
}
#slideClientes .swiper-wrapper {
  height: 200px !important;
}
/* #slideFasesProd .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 0px !important;
} */
/* #slideFasesProd .swiper-container{
  overflow: visible !important;
} */